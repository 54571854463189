import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tw from "tailwind.macro";
import { width as twWidth } from "../../tailwind";
import { hidden } from "../styles/utils";

const Wrapper = styled.svg`
  ${tw`absolute`};
  stroke: currentColor;
  ${props => props.hiddenMobile && hidden};
  color: ${props => props.stroke};
  width: ${props => props.svgWidth};
  fill: ${props => props.fill};
  left: ${props => props.left};
  top: ${props => props.top};
`;

const icons = {
  triangle: {
    shape: (
      <polygon
        strokeWidth="1px"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="14.921,2.27 28.667,25.5 1.175,25.5 "
      />
    ),
    viewBox: "0 0 30 30"
  },
  circle: {
    shape: (
      <path d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z" />
    ),
    viewBox: "0 0 30 30"
  },
  arrowUp: {
    shape: (
      <>
        <path d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z" />{" "}
        <path d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z" />
      </>
    ),
    viewBox: "0 0 30 42"
  },
  upDown: {
    shape: (
      <>
        <path d="M28.74,44.58a1.28,1.28,0,0,1-1-.51L15.16,27.13l-12.89,17a1.26,1.26,0,1,1-2-1.53l13.9-18.29a1.34,1.34,0,0,1,1-.5,1.24,1.24,0,0,1,1,.51L29.75,42.56a1.27,1.27,0,0,1-1,2Z" />
        <path d="M14.83,20.82h0a1.28,1.28,0,0,1-1-.52L.25,2a1.27,1.27,0,0,1,2-1.51L14.84,17.45,27.73.5a1.26,1.26,0,0,1,2,1.53L15.84,20.32A1.28,1.28,0,0,1,14.83,20.82Z" />
      </>
    ),
    viewBox: "0 0 30 44.58"
  },
  box: {
    shape: (
      <path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
    ),
    viewBox: "0 0 30 30"
  },
  hexa: {
    shape: (
      <polygon
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
      />
    ),
    viewBox: "0 0 30 30"
  },
  cross: {
    shape: (
      <path
        strokeWidth="3px"
        d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
      />
    ),
    viewBox: "0 0 100 100"
  },
  camera: {
    shape: (
      <>
        <path
          d="M55.201,15.5h-8.524l-4-10H17.323l-4,10H12v-5H6v5H4.799C2.152,15.5,0,17.652,0,20.299v29.368
		C0,52.332,2.168,54.5,4.833,54.5h50.334c2.665,0,4.833-2.168,4.833-4.833V20.299C60,17.652,57.848,15.5,55.201,15.5z M8,12.5h2v3H8
		V12.5z M58,49.667c0,1.563-1.271,2.833-2.833,2.833H4.833C3.271,52.5,2,51.229,2,49.667V20.299C2,18.756,3.256,17.5,4.799,17.5H6h6
		h2.677l4-10h22.646l4,10h9.878c1.543,0,2.799,1.256,2.799,2.799V49.667z"
        />
        <path
          d="M30,14.5c-9.925,0-18,8.075-18,18s8.075,18,18,18s18-8.075,18-18S39.925,14.5,30,14.5z M30,48.5c-8.822,0-16-7.178-16-16
		s7.178-16,16-16s16,7.178,16,16S38.822,48.5,30,48.5z"
        />
        <path
          d="M30,20.5c-6.617,0-12,5.383-12,12s5.383,12,12,12s12-5.383,12-12S36.617,20.5,30,20.5z M30,42.5c-5.514,0-10-4.486-10-10
		s4.486-10,10-10s10,4.486,10,10S35.514,42.5,30,42.5z"
        />
        <path
          d="M52,19.5c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S54.206,19.5,52,19.5z M52,25.5c-1.103,0-2-0.897-2-2s0.897-2,2-2
		s2,0.897,2,2S53.103,25.5,52,25.5z"
        />
      </>
    ),
    viewBox: "0 0 60 60"
  },
  video: {
    shape: (
      <path
        fill="currentColor"
        d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z"
      />
    ),
    viewBox: "0 0 576 512"
  },
  leaf: {
    shape: (
      <path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z" />
    ),
    viewBox: "0 0 576 512"
  },
  envira: {
    shape: (
      <path
        fill="currentColor"
        d="M0 32c477.6 0 366.6 317.3 367.1 366.3L448 480h-26l-70.4-71.2c-39 4.2-124.4 34.5-214.4-37C47 300.3 52 214.7 0 32zm79.7 46c-49.7-23.5-5.2 9.2-5.2 9.2 45.2 31.2 66 73.7 90.2 119.9 31.5 60.2 79 139.7 144.2 167.7 65 28 34.2 12.5 6-8.5-28.2-21.2-68.2-87-91-130.2-31.7-60-61-118.6-144.2-158.1z"
      />
    ),
    viewBox: "0 0 448 512"
  },
  pagelines: {
    shape: (
      <path
        fill="currentColor"
        d="M384 312.7c-55.1 136.7-187.1 54-187.1 54-40.5 81.8-107.4 134.4-184.6 134.7-16.1 0-16.6-24.4 0-24.4 64.4-.3 120.5-42.7 157.2-110.1-41.1 15.9-118.6 27.9-161.6-82.2 109-44.9 159.1 11.2 178.3 45.5 9.9-24.4 17-50.9 21.6-79.7 0 0-139.7 21.9-149.5-98.1 119.1-47.9 152.6 76.7 152.6 76.7 1.6-16.7 3.3-52.6 3.3-53.4 0 0-106.3-73.7-38.1-165.2 124.6 43 61.4 162.4 61.4 162.4.5 1.6.5 23.8 0 33.4 0 0 45.2-89 136.4-57.5-4.2 134-141.9 106.4-141.9 106.4-4.4 27.4-11.2 53.4-20 77.5 0 0 83-91.8 172-20z"
      />
    ),
    viewBox: "0 0 384 512"
  },
  cog: {
    shape: (
      <path
        fill="currentColor"
        d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
      />
    ),
    viewBox: "0 0 512 512"
  },
  doubleDown: {
    shape: (
      <path
        fill="currentColor"
        d="M143 256.3L7 120.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0L313 86.3c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.4 9.5-24.6 9.5-34 .1zm34 192l136-136c9.4-9.4 9.4-24.6 0-33.9l-22.6-22.6c-9.4-9.4-24.6-9.4-33.9 0L160 352.1l-96.4-96.4c-9.4-9.4-24.6-9.4-33.9 0L7 278.3c-9.4 9.4-9.4 24.6 0 33.9l136 136c9.4 9.5 24.6 9.5 34 .1z"
      />
    ),
    viewBox: "0 0 320 512"
  },
  france: {
    shape: (
      <path
        d="M156.095,219.297h-18.856c-1.381,0-2.5-1.119-2.5-2.5c0-13.835-11.255-25.091-25.09-25.091s-25.091,11.256-25.091,25.091
	c0,1.381-1.119,2.5-2.5,2.5H63.202c-0.888,0-1.709-0.471-2.158-1.238c-0.448-0.767-0.456-1.714-0.021-2.488l19.743-35.1v-9.935
	c0-1.381,1.119-2.5,2.5-2.5h2.232l9.486-31.335l-3.637-4.547c-0.601-0.75-0.717-1.778-0.301-2.645
	c0.417-0.866,1.292-1.417,2.253-1.417h2.408c1.055-5.604,3.976-23.151,5.313-51.681c1.651-35.234,2.038-51.247,2.041-51.404
	c0-0.019,0.001-0.037,0.002-0.055c-0.974-0.948-1.58-2.272-1.58-3.736v-6.045c0-2.875,2.339-5.214,5.213-5.214h0.452V2.5
	c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5v7.458h0.451c2.875,0,5.214,2.339,5.214,5.214v6.045c0,1.463-0.606,2.788-1.58,3.736
	c0,0.018,0.001,0.037,0.002,0.055c0.003,0.158,0.391,16.183,2.041,51.405c1.338,28.53,4.259,46.077,5.313,51.681h2.408
	c0.961,0,1.837,0.551,2.253,1.417c0.416,0.866,0.299,1.895-0.301,2.645l-3.637,4.547l9.486,31.335h2.231c1.381,0,2.5,1.119,2.5,2.5
	v9.935l19.744,35.1c0.436,0.774,0.428,1.721-0.021,2.488C157.804,218.826,156.983,219.297,156.095,219.297z M139.636,214.297h12.185
	l-17.252-30.671H84.729l-17.252,30.671h12.184c1.274-15.427,14.237-27.591,29.988-27.591S138.361,198.87,139.636,214.297z
	 M85.767,178.626h47.763v-5.59H85.767V178.626z M122.355,168.036h6.219l-9.428-31.146c-0.239-0.788-0.074-1.643,0.44-2.286
	l1.209-1.512H98.501l1.209,1.512c0.514,0.643,0.679,1.498,0.44,2.286l-9.428,31.146h6.219l4.355-23.228
	c0.222-1.182,1.254-2.039,2.457-2.039h11.79c1.203,0,2.235,0.857,2.457,2.039L122.355,168.036z M102.028,168.036h15.24l-3.8-20.267
	h-7.641L102.028,168.036z M100.799,128.092h17.698c-1.21-6.588-3.918-23.779-5.215-51.446c-1.291-27.542-1.811-43.378-1.979-49.08
	h-3.311c-0.168,5.702-0.688,21.539-1.979,49.08C104.718,104.313,102.009,121.504,100.799,128.092z M106.697,14.958
	c-0.118,0-0.213,0.096-0.213,0.214v6.045c0,0.118,0.096,0.214,0.213,0.214h5.903c0.118,0,0.214-0.096,0.214-0.214v-6.045
	c0-0.118-0.096-0.214-0.214-0.214H106.697z"
      />
    ),
    viewBox: "0 0 219.297 219.297"
  },
  brain: {
    shape: (
      <path
        fill="currentColor"
        d="M208 0c-29.87 0-54.74 20.55-61.8 48.22-.75-.02-1.45-.22-2.2-.22-35.34 0-64 28.65-64 64 0 4.84.64 9.51 1.66 14.04C52.54 138 32 166.57 32 200c0 12.58 3.16 24.32 8.34 34.91C16.34 248.72 0 274.33 0 304c0 33.34 20.42 61.88 49.42 73.89-.9 4.57-1.42 9.28-1.42 14.11 0 39.76 32.23 72 72 72 4.12 0 8.1-.55 12.03-1.21C141.61 491.31 168.25 512 200 512c39.77 0 72-32.24 72-72V205.45c-10.91 8.98-23.98 15.45-38.36 18.39-4.97 1.02-9.64-2.82-9.64-7.89v-16.18c0-3.57 2.35-6.78 5.8-7.66 24.2-6.16 42.2-27.95 42.2-54.04V64c0-35.35-28.66-64-64-64zm368 304c0-29.67-16.34-55.28-40.34-69.09 5.17-10.59 8.34-22.33 8.34-34.91 0-33.43-20.54-62-49.66-73.96 1.02-4.53 1.66-9.2 1.66-14.04 0-35.35-28.66-64-64-64-.75 0-1.45.2-2.2.22C422.74 20.55 397.87 0 368 0c-35.34 0-64 28.65-64 64v74.07c0 26.09 17.99 47.88 42.2 54.04 3.46.88 5.8 4.09 5.8 7.66v16.18c0 5.07-4.68 8.91-9.64 7.89-14.38-2.94-27.44-9.41-38.36-18.39V440c0 39.76 32.23 72 72 72 31.75 0 58.39-20.69 67.97-49.21 3.93.67 7.91 1.21 12.03 1.21 39.77 0 72-32.24 72-72 0-4.83-.52-9.54-1.42-14.11 29-12.01 49.42-40.55 49.42-73.89z"
      />
    ),
    viewBox: "0 0 576 512"
  },
  thumb: {
    shape: (
      <path
        fill="currentColor"
        d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z"
      />
    ),
    viewBox: "0 0 512 512"
  },
  toro: {
    shape: (
      <path
        d="M237.691,318.246c-1.945,0.69-3.451,2.069-4.391,3.826
		c-1.004,1.819-1.254,3.826-0.878,5.833c0.439,2.007,1.506,3.701,3.074,4.956c1.63,1.254,3.512,1.882,5.583,1.819
		c6.963-3.576,14.741-3.89,22.017-1.004c6.273-1.569,12.545-1.066,18.505,1.568c1.443,0.502,2.007,1.129,4.078,0
		c2.069-1.192,3.764-5.458,3.387-8.782c-0.439-3.325-2.446-6.148-5.458-7.653c-0.564-0.25-2.321-0.815-1.693-0.815
		c0.689-0.063,0-0.188,2.132-0.063c2.07,0.126,6.524,1.756,8.908,4.266c2.384,2.572,3.638,5.771,3.575,9.284
		c0,3.512-1.254,6.65-3.638,9.221c-2.384,2.572-5.52,4.015-8.97,4.266c2.822,0.377,5.207,1.694,7.087,3.89
		c-0.94,2.572-2.948,4.454-5.582,5.27c7.214,4.39,13.11,9.471,18.505,15.995c32.932,1.003,37.637,3.199,60.03-0.063
		c6.211,0.063,11.856,0.063,11.104,5.206c6.963,8.218,4.83,17.752,5.019,27.978c15.682,34.877-7.842,36.695,5.27,54.01
		c-5.333-1.004-9.473-5.459-10.1-10.916c-1.067,9.66-5.332,7.277-2.572,24.15l-2.446,0.252l-0.063,4.139h-8.72
		c9.034-28.855,3.764-34.688-0.251-42.779c0.376,1.691,0,3.449-1.129,4.893c-1.129,1.379-2.697,2.133-4.517,2.133
		c-1.757,0-3.387-0.754-4.517-2.133c-1.129-1.443-1.505-3.201-1.129-4.893c-2.383,1.441-4.076,3.637-4.768,6.334
		c-0.752,2.697-0.376,5.457,0.941,7.904l-1.255,2.949c-6.021,5.707-10.225,12.295-12.797,20.262l-1.38-0.127v5.332h-8.279
		c1.756-7.088,4.83-13.172,9.408-18.881c6.398-13.109-0.563-8.908-1.129-23.586c-2.948,1.316-5.395,3.074-7.652,5.332
		c0.627-1.693,0.313-3.512-0.815-4.83c-1.192-1.381-2.949-1.945-4.643-1.506c-5.708,0.879-10.977,1.381-16.748,1.693
		c0.815,15.871-5.269,1.131-3.575,36.822l-1.819,0.063l0.125,5.018h-8.531c4.014-11.416,2.571-31.865-0.691-41.775
		c-7.087,9.66-12.796,4.703-18.253-0.064c-0.941,7.654,0,17.25-2.384,19.133c-2.697,12.609,1.443,9.221,0,17.063l-1.255,0.125v5.145
		l-8.468,0.188c-0.376-4.078,2.259-9.033,2.447-11.479c0.189-2.51,0.189-5.207-0.125-6.713c-0.565-3.01-2.07-7.527-2.321-14.74
		c0.251-22.018-10.224-15.996-7.213-49.556c-3.074-12.797-4.078-24.903-3.199-38.075c-5.081,0.501-9.723-1.13-13.173-4.894
		l1.569-1.066l-2.071-0.564c1.066-1.381,1.882-2.76,3.262-3.826c-3.137-0.69-5.771-2.447-7.653-5.019
		c-1.881-2.634-2.697-5.646-2.321-8.907c0.314-3.199,1.694-5.959,4.078-8.155C231.544,319.5,234.429,318.308,237.691,318.246
		L237.691,318.246z M374.815,435.359c-3.136-7.15-3.136-14.93,0-22.08C377.951,420.43,377.951,428.209,374.815,435.359
		L374.815,435.359z"
      />
    ),
    viewBox: "200 300 200 300"
  }
};

const SVG = ({ stroke, fill, width, icon, left, top, hiddenMobile }) => (
  <Wrapper
    viewBox={icons[icon].viewBox}
    stroke={stroke}
    fill={fill}
    svgWidth={twWidth[`${width}`]}
    left={left}
    top={top}
    hiddenMobile={hiddenMobile}
  >
    {icons[icon].shape}
  </Wrapper>
);

export default SVG;

SVG.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  left: PropTypes.string,
  top: PropTypes.string,
  hiddenMobile: PropTypes.bool
};

SVG.defaultProps = {
  stroke: "transparent",
  width: 8,
  fill: "none",
  left: "0%",
  top: "0%",
  hiddenMobile: false
};
